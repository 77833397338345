<template>
  <v-dialog v-model="isShown" scrollable persistent max-width="640">
    <v-card v-if="satisfaction">
      <v-card-title fixed>{{ dynamicTranslations.title[satisfaction] }} </v-card-title>
      <v-card-text class="pt-10">
        {{ dynamicTranslations.text[satisfaction] }}
        <v-item-group v-if="satisfaction === 'negative'">
          <skr-checkbox v-model="formData.missingFeatures" class="mt-8 mb-0 pb-0">
            <span
              class="checkbox__label"
              :class="`checkbox__label--${formData.missingFeatures ? 'active' : 'inactive'}`"
              >{{ $t('dialog.relationship.csat.negative.option_1.label') }}</span
            >
          </skr-checkbox>
          <skr-checkbox v-model="formData.signingDocuments" class="mt-6 mb-0 pt-0 pb-0">
            <span
              class="checkbox__label"
              :class="`checkbox__label--${formData.signingDocuments ? 'active' : 'inactive'}`"
              >{{ $t('dialog.relationship.csat.negative.option_2.label') }}</span
            >
          </skr-checkbox>
          <skr-checkbox v-model="formData.findingInformation" class="mt-6 mb-0 pt-0 pb-0">
            <span
              class="checkbox__label"
              :class="`checkbox__label--${formData.findingInformation ? 'active' : 'inactive'}`"
              >{{ $t('dialog.relationship.csat.negative.option_3.label') }}</span
            >
          </skr-checkbox>
          <skr-checkbox v-model="formData.hardToUse" class="mt-6 mb-0 pt-0 pb-0">
            <span class="checkbox__label" :class="`checkbox__label--${formData.hardToUse ? 'active' : 'inactive'}`">{{
              $t('dialog.relationship.csat.negative.option_4.label')
            }}</span>
          </skr-checkbox>
        </v-item-group>
        <v-item-group v-if="satisfaction === 'positive'">
          <skr-checkbox v-model="formData.easeOfUse" class="mt-8 mb-0 pt-0 pb-0">
            <span class="checkbox__label" :class="`checkbox__label--${formData.easeOfUse ? 'active' : 'inactive'}`">{{
              $t('dialog.relationship.csat.positive.option_1.label')
            }}</span>
          </skr-checkbox>
          <skr-checkbox v-model="formData.features" class="mt-6 mb-0 pt-0 pb-0">
            <span class="checkbox__label" :class="`checkbox__label--${formData.features ? 'active' : 'inactive'}`">{{
              $t('dialog.relationship.csat.positive.option_2.label')
            }}</span>
          </skr-checkbox>
          <skr-checkbox v-model="formData.design" class="mt-6 mb-0 pt-0 pb-0">
            <span class="checkbox__label" :class="`checkbox__label--${formData.design ? 'active' : 'inactive'}`">{{
              $t('dialog.relationship.csat.positive.option_3.label')
            }}</span>
          </skr-checkbox>
          <skr-checkbox v-model="formData.signing" class="mt-6 mb-0 pt-0 pb-0">
            <span class="checkbox__label" :class="`checkbox__label--${formData.signing ? 'active' : 'inactive'}`">{{
              $t('dialog.relationship.csat.positive.option_4.label')
            }}</span>
          </skr-checkbox>
        </v-item-group>

        <v-textarea
          v-model="formData.freetext"
          auto-grow
          :label="dynamicTranslations.freetextLabel[satisfaction]"
          name="freetext"
          maxlength="250"
          rows="3"
          clearable
          class="mt-12"
          :class="{ 'mt-10': satisfaction === 'neutral' }"
        />
      </v-card-text>

      <v-divider />
      <v-card-actions fixed>
        <v-btn :disabled="loading" size="x-large" color="primary" variant="outlined" @click="skip()">
          {{ $t('global.skip') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading || isDisabled"
          :loading="loading"
          size="x-large"
          color="primary"
          variant="elevated"
          @click="submit()"
        >
          {{ $t('global.submit_feedback') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SkrCheckbox from '@/components/SkrCheckbox.vue'

export default defineComponent({
  name: 'DialogRelationshipCsat',
  components: {
    SkrCheckbox,
  },
  props: {
    satisfaction: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const { id: businessId } = storeToRefs(useBusinessStore())
    const { email: userEmail, firstName, lastName } = storeToRefs(useUserStore())
    const { createSnackbar } = useWidgetStore()

    const { hubspotRepository } = useApi()

    return {
      businessId,
      userEmail,
      firstName,
      lastName,
      createSnackbar,
      sendHubspotData: hubspotRepository.sendData,
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        freetext: '',
        easeOfUse: false,
        features: false,
        design: false,
        signing: false,
        missingFeatures: false,
        signingDocuments: false,
        findingInformation: false,
        hardToUse: false,
      },
    }
  },
  computed: {
    onProduction(): boolean {
      return this.$config.public.mode === 'production'
    },
    dynamicTranslations(): TranslationKeysObject {
      return {
        title: {
          negative: this.$t('dialog.relationship.csat.negative.title'),
          neutral: this.$t('dialog.relationship.csat.neutral.title'),
          positive: this.$t('dialog.relationship.csat.positive.title'),
        },
        freetextLabel: {
          negative: this.$t('dialog.relationship.csat.negative.freetext.label'),
          neutral: this.$t('dialog.relationship.csat.neutral.freetext.label'),
          positive: this.$t('dialog.relationship.csat.positive.freetext.label'),
        },
        text: {
          negative: this.$t('dialog.relationship.csat.negative.text'),
          neutral: this.$t('dialog.relationship.csat.neutral.text'),
          positive: this.$t('dialog.relationship.csat.positive.text'),
        },
      }
    },
    isDisabled(): boolean {
      return !Object.values(this.formData).some(Boolean)
    },
    isShown: {
      get(): boolean {
        return this.modelValue
      },
      set(newValue: boolean): void {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  methods: {
    async sendFeedback(): Promise<void> {
      this.loading = true
      // Different object name on prod and sandbox environment:
      const positiveOptionsFieldName = this.onProduction
        ? 'sb_trial___detailed_feedback___csat_relationship___positive'
        : 'sb_trial___detailed_feedback'
      // Same object name on prod and sandbox environment:
      const negativeOptionsFieldName = 'sb_trial___detailed_feedback___csat_relationship___negative'
      const generalFields = [
        {
          name: 'email',
          value: this.userEmail,
        },
        {
          name: 'firstname',
          value: this.firstName,
        },
        {
          name: 'lastname',
          value: this.lastName,
        },
        {
          name: this.onProduction
            ? 'sb_trial___general_customer_satisfaction__structured____csat_relationship'
            : 'sb_trial___general_customer_satisfaction__structured_',
          value: this.onProduction
            ? this.satisfaction.charAt(0).toUpperCase() + this.satisfaction.slice(1)
            : this.satisfaction,
        },
        {
          name: this.onProduction
            ? 'sb_trial___general_customer_satisfaction___csat_relationship___free_text'
            : 'sb_trial___general_customer_satisfaction',
          value: this.formData.freetext,
        },
        {
          name: this.onProduction ? '0-2/skribble_biz_id' : '0-2/skribble_business_id',
          value: this.businessId,
        },
      ]
      const fields = {
        positive: [
          this.formData.easeOfUse && {
            name: positiveOptionsFieldName,
            value: 'Ease-of-use',
          },
          this.formData.features && {
            name: positiveOptionsFieldName,
            value: 'Features',
          },
          this.formData.design && {
            name: positiveOptionsFieldName,
            value: 'Design',
          },
          this.formData.signing && {
            name: positiveOptionsFieldName,
            value: 'Signing',
          },
        ],
        neutral: [],
        negative: [
          this.formData.missingFeatures && {
            name: negativeOptionsFieldName,
            value: 'Missing features',
          },
          this.formData.signingDocuments && {
            name: negativeOptionsFieldName,
            value: 'Singing documents',
          },
          this.formData.findingInformation && {
            name: negativeOptionsFieldName,
            value: 'Finding information',
          },
          this.formData.hardToUse && {
            name: negativeOptionsFieldName,
            value: 'Hard to use',
          },
        ],
      }
      await this.sendHubspotData(
        this.$config.public.hubspotPortalId,
        this.onProduction ? '9b2e0a34-356f-41e6-9601-6a25a24c4d8a' : '27b5b6a2-fe6b-47d8-8d6e-e4d64aa2c70a',
        {
          submittedAt: Date.now(),
          fields: generalFields.concat(fields[this.satisfaction]).filter(Boolean),
        }
      )
      this.isShown = false
    },
    async submit(): Promise<void> {
      this.loading = false
      await this.sendFeedback()
      this.createSnackbar({
        message: this.$t('snackbar.feedback.thank_you'),
      })
      this.isShown = false
    },
    async skip(): Promise<void> {
      await this.sendFeedback()
      this.isShown = false
    },
  },
})
</script>

<style lang="sass">
.checkbox
  &__label
    font-size: 1.25rem
    font-weight: bold
    &--inactive
      color: $c-skribbleu
</style>
